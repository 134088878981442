<template>
  <v-container>
    <v-layout justify-center row>
      <!-- <v-btn @click="deodorizer()"> -->
      <v-btn @click="deodorizerDevice()">
        <v-img src="@/assets/images/deodorizerHome_green.svg" alt="기계실 현황" height="200px" width="200px" />
      </v-btn>

      <v-btn @click="deodorizer()">
        <v-img src="@/assets/images/deodorizersetHome_green.svg" alt="악취 제어" height="200px" width="200px" />
      </v-btn> 

    </v-layout>
  </v-container>
</template>

<script>
// import Apis from '@/api/apis';

export default {
  name: "DeodorizerHome",

  created(){

  },

  data(){
    return{

    }
  },

  methods:{
    deodorizer(){
      this.$router.push("/deodorizer/deodorizermonitoring") 
      // this.$router.push("/deodorizer/deodorizerset") 
    },
    deodorizerDevice(){
      // this.$router.push("/deodorizer/deodorizerdviceMonitoring") 
      this.$router.push("/deodorizer/DeodorizerDviceMonitoring") 
      // this.$router.push("/deodorizer/deodorizerset") 
    }
  },
}

</script>

<style lang="scss" scoped>
.v-btn {
  display: flex;
  width: auto !important;
  height: auto !important;
  align-items: bottom !important;
  height: 47px;
  border: none !important;
  background-color: white !important;
  cursor: pointer;
  margin: 20px !important;
  padding: 0px !important;
}
</style>