import { render, staticRenderFns } from "./DeodorizerHome.vue?vue&type=template&id=47514587&scoped=true"
import script from "./DeodorizerHome.vue?vue&type=script&lang=js"
export * from "./DeodorizerHome.vue?vue&type=script&lang=js"
import style0 from "./DeodorizerHome.vue?vue&type=style&index=0&id=47514587&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47514587",
  null
  
)

export default component.exports